<template>
    <!-- Terms Modal -->
    <div class="modal fade modalbox" id="privacyModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Privacy Policy</h5>
                    <a href="javascript:void(0);" data-bs-dismiss="modal">Close</a>
                </div>
                <div class="modal-body">
                    <h3>Privacy & Policy</h3>
                    <h5>Last modified: 9 March 2021</h5>
                    <hr>

                    <h4>Introduction</h4>
                    <p>
                        This Privacy Policy explains who we are, why and how we process personal data collected through your use of the App and, if you are the subject of any of the personal data concerned, what rights you have and how to get in touch with us if you need to.                    <p>
                    Earn Relai is the controller of your personal data collected through the App and we are committed to protecting and respecting your privacy. Earn Relai is dedicated to collect as little personal data about its users as possible.
                </p>
                    <p>
                        When you supply any personal data to us, we have legal obligations towards you in the way we use that data. We must collect the information fairly and explain to you how we will use it. For ease of reading, we have divided this Privacy Policy into several sections:
                    </p>
                    <ul>
                        <li>Introduction</li>
                        <li>What information we collect</li>
                        <li>How and why we use/share your information</li>
                        <li>How long we keep your information</li>
                        <li>Security</li>
                        <li>International Data Transfers</li>
                        <li>Your Rights</li>
                        <li>Contact Details</li>
                    </ul>
                    <p>
                        It is important that you read this Privacy Policy together with any other privacy notice or fair processing notice that we may provide at or around the time that we collect or process personal data about you so that you are fully aware of how and why we are using that data.
                    </p>
                    <p>
                        By downloading and/or using our App, you agree to its Terms (including as amended from time to time) and this Privacy Policy. If, for any reason, you do not agree to the Terms and/or to this Privacy Policy, please stop using the App.
                    </p>
                    <p>
                        We reserve the right to revise or amend this Privacy Policy at any time to reflect changes to our business or changes in the law. It is your responsibility to check this Privacy Policy before each use of the App. Your continued use of the App after any such changes, with or without having explicitly accepted the new Privacy Policy, shall constitute your consent to such changes.
                    </p>
                    <p>
                        Please note that the App is not intended for use by nor directed at anyone under the age of 18 and we do not knowingly collect data relating to children. If you believe we have collected personal information about your child, you may contact us at
                        <a href="mailto:Samantha@earnrelai.com">Samantha@earnrelai.com</a> and request that we remove information about him/her.
                    </p>

                    <h4>Privacy Policy for SSL/TLS Encryption</h4>
                    <p>
                        This website uses SSL/TLS encryption for reasons of security and to protect the transmission of confidential content, such as the requests you send to us as site operator. You can recognize an encrypted connection by the fact that the address line of your browser changes from “http://” to “https://” and by the lock symbol in your browser line.
                    </p>
                    <p>
                        If the SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.
                    </p>

                    <h4>What Information we collect</h4>
                    <h6>What is personal data?</h6>
                    <p>
                        Where this Privacy Policy refers to ‘personal data’ it is referring to data about you from which you could be identified – such as your name, your date of birth, your contact details and even your IBAN or IP address.
                        By law, all organizations who process your personal data in Europe (including Switzerland) are obliged to process your personal data in certain ways and to ensure that you are given an appropriate amount of information about how they use it. You also have various rights to seek information from those organizations about how they are using your data, and to prevent them from processing it unlawfully. For more information about these rights, please see the ‘Your Rights’ section of this Privacy Policy.
                    </p>

                    <h6>How and what types of data we collect from you when you use the Web App</h6>
                    <p>
                        When you use the App to create a wallet, an account or profile, fill in forms provided on the App, subscribe to our services, notifications or newsletters, or report a problem with the App, we may collect, store and use certain personal information that you disclose to us.
                    </p>
                    <p>
                        The information we collect from you may include (but is not limited to): your IP address, IBAN, email address, telephone number, feedback and log-in information.
                    </p>
                    <p>
                        We shall also collect information about you when you visit and interact with the App through the use of technologies such as cookies. The following are examples of information we may collect:
                    </p>
                    <ul>
                        <li>information about your device, browser or operating system;</li>
                        <li>time zone setting;</li>
                        <li>your IP address;</li>
                        <li>information about interactions you have with the App (such as scrolling and clicks);</li>
                        <li>information about the way you use and the actions you take within the App;</li>
                        <li>length of time on the App and time spent within certain sections;</li>
                        <li>response times; and</li>
                        <li>download errors.</li>
                    </ul>
                    <p>
                        Please refer to your device’s help material to learn what controls you can use to remove cookies, block cookies, or disable IDFA tracking. If you do this, it may affect your use of any website or any app, including Earn Relai’s App (Earn Relai’s website or application)
                    </p>
                    <p>
                        We may also partner with third parties who may collect anonymous usage or statistical data through your use of the App (including, for example, sub-contractors in technical, payment and/or delivery services, business partners, advertising networks, analytics providers).
                    </p>
                    <p>
                        We may also collect information about how you use the App through Google Play’s Android Advertising ID technology and Apple iOS’s Advertising Identifier. This is used only by us and our trusted third-party service providers (see “Service Providers” below) for advertising and user analytics. The advertising identifier will only be connected to personally identifiable information where you have provided us with your explicit consent. We will respect your user selections for this technology and will not use the data in any way which you have asked us not to. We are committed to abiding by Google’s and Apple’s terms of use and you may contact us (<a
                        href="mailto:Samantha@earnrelai.com">Samantha@earnrelai.com</a>), Google or Apple directly if you have any questions regarding our use of this technology.
                    </p>

                    <h6>Updating your information</h6>
                    <p>
                        If you want to update the information you have previously given to us, you can contact us at <a
                        href="mailto:Samantha@earnrelai.com">Samantha@earnrelai.com</a>
                    </p>

                    <h4>How and why we use/share your Information</h4>
                    <h6>Lawful basis for processing your information</h6>
                    <p>
                        We will only use your personal data when the law allows us to. Most commonly we will use your personal data in the following circumstances:
                    </p>
                    <ul>
                        <li>Where you have asked us to do so, or consented to us doing so;</li>
                        <li>Where we need to do so in order to perform a contract we have entered into with you;</li>
                        <li>Where it is necessary for our legitimate interests (or those of a third party) and your fundamental rights do not override those interests;</li>
                        <li>Where we need to comply with a legal or regulatory obligation; and</li>
                        <li>For marketing messages</li>
                    </ul>
                    <p>
                        You will receive marketing messages from us if you have given us your consent to do so or if you have provided feedback on our App and have not opted out of receiving marketing messages.
                    </p>
                    <p>
                        To unsubscribe from marketing emails at any time, please click on the unsubscribe link at the bottom of any marketing email and update your account preferences. You may also contact us (Samantha@earnrelai.com) to inform us if you do not wish to receive any marketing materials from us.
                    </p>

                    <h6>Sharing your information</h6>
                    <p>
                        Depending on how and why you provide us with your personal data, we may share it in the following ways:
                    </p>
                    <ul>
                        <li> with any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries</li>
                        <li>with selected third parties including business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you (see “Service Providers” below); or</li>
                        <li>with analytics that assist us in the improvement and optimization of the App</li>

                    </ul>
                    <p>
                        We may also disclose your personal information to third parties in the following events:
                    </p>
                    <ul>
                        <li>if we were to sell or buy any business or assets, in which case we might disclose your personal information to the prospective seller or buyer of such business or assets;</li>
                        <li>if Earn Relai or substantially all of its assets are acquired by a third party, in which case personal information held by us about our customers will be one of the transferred assets; or</li>
                        <li>if we are under a duty to disclose or share your personal information in order to comply with any legal obligation, or to protect the rights, property, or safety of our company, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
                    </ul>

                    <h6>Service Providers</h6>
                    <p>
                        Our service providers provide us with a variety of administrative, statistical, and technical services. We will only provide service providers with the minimum amount of personal data they need to fulfil the services we request, and we stipulate that they protect this information and do not use it for any other purpose. We take these relationships seriously and oblige all of our data processors to sign contracts with us that clearly set out their commitment to respecting individual rights, and their commitments to assisting us to help you exercise your rights as a data subject
                    </p>

                    <h6>Other Disclosures</h6>
                    <p>
                        During your use of the App, your app store provider and mobile network operator may also collect personal information about you regarding your use of the App such as your identity, your usage and location.
                        These third parties shall act as separate and independent controllers of that personal data and shall process it in accordance with their own privacy policy.
                    </p>

                    <h6>Links to third party sites</h6>
                    <p>
                        The App may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. We may also provide links to third party websites that are not affiliated with the App. All third-party websites are out of our control and are not covered by this Privacy Policy. If you access third party sites using the links provided, the operators of these sites may collect information from you that could be used by them, in accordance with their own privacy policies. Please check these policies before you submit any personal data to those websites.
                    </p>

                    <h4>How long do we keep your Information</h4>
                    <p>
                        We will hold your personal information on our systems only for as long as required to provide you with the products and services you have requested, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.
                    </p>
                    <p>
                        In some circumstances you can ask us to delete your data: see ‘Your Rights’ below for further information.
                    </p>
                    <p>
                        In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.
                    </p>

                    <h4>Security</h4>
                    <p>
                        Earn Relai takes the protection of your information very seriously. We have put in place appropriate physical, electronic and managerial security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed, including use of secure servers, passwords and industry standard encryption for data both in transit and at rest.
                    </p>
                    <p>
                        Where we have given you a password or PIN code that enables you to access certain parts of our App, you are responsible for keeping this password or PIN code confidential. We ask you not to share a password or PIN code with anyone.
                    </p>
                    <p>
                        When first launching the App, it creates an individual encrypted digital wallet, i.e. a private key, for every user to store his/her crypto assets.Earn Relai does not have, at any point in time, access to the user’s private keys and funds. You are responsible for keeping your private key confidential. We ask you not to share your private key with anyone. If you lose or give away your private key, you lose or give away your crypto assets.
                    </p>
                    <p>
                        We limit access to your personal data to those employees, agents, contractors and other third parties who have a business, need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
                    </p>
                    <p>
                        We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
                    </p>

                    <h4> International Data Transfers</h4>
                    <p>
                        Please note that some of our service providers may be based outside of the European Economic Area (the “EEA”). These service providers may work for us or for one of our suppliers and may be engaged in, among other things, the fulfilment of your request for information, products and services, the processing of your payment details and the provision of support services.
                        Where we transfer your data to a service provider that is outside of the EEA we seek to ensure that appropriate safeguards are in place to make sure that your personal data is held securely and that your rights as a data subject are upheld. Transfers of personal data are either made:
                    </p>
                    <ul>
                        <li>to a country recognised by the European Commission as providing an adequate level of protection; or</li>
                        <li>to a country which does not offer adequate protection but whose transfer has been governed by the standard contractual clauses of the European Commission or by implementing other appropriate cross-border transfer solutions to provide adequate protection.</li>
                    </ul>
                    <p>
                        By submitting your personal information, you agree to this transfer, storing or processing.
                        If you would like more information about how the mechanism via which your personal data is transferred, please contact
                        <a href="mailto:Samantha@earnrelai.com">Samantha@earnrelai.com</a>.
                    </p>

                    <h4>Your Rights</h4>
                    <p>
                        As a data subject you have a number of rights in relation to your personal data. Below, we have described the various rights that you have as well as how you can exercise them.
                    </p>
                    <h6>Right of Access</h6>
                    <p>
                        You may, at any time, request access to the personal data that we hold which relates to you (you may have heard of this right being described as a “subject access request”).
                    </p>
                    <p>
                        Please note that this right entitles you to receive a copy of the personal data that we hold about you in order to enable you to check that it is correct and to ensure that we are processing that personal data lawfully. It is not a right that allows you to request personal data about other people, or a right to request specific documents from us that do not relate to your personal data.
                    </p>
                    <p>
                        You can exercise this right at any time by writing to us (<a
                        href="mailto:Samantha@earnrelai.com">Samantha@earnrelai.com</a>) and telling us that you are making a “subject access request”. You do not have to fill in a specific form to make this kind of request.
                    </p>

                    <h6>Your Right to Rectification and Erasure</h6>
                    <p>
                        You may, at any time, request that we correct personal data that we hold about you which you believe is incorrect or inaccurate. Please note that we may ask you to verify any new data that you provide to us and may take our own steps to check that the new data you have supplied us with is right.
                        You may also ask us to erase personal data that we control if you do not believe that we need to continue retaining it (you may have heard of this right described as the “right to be forgotten”). Although we will do everything to respect your request and personal data, it may not always be possible to erase all of your personal data as there may be legal requirements to keep certain personal data or technical limitations to the data we can delete.
                        There may also be legitimate interests in keeping certain data including, amongst others, if the data is required for the App to function. If this is the case, we will continue to process this data.
                    </p>
                    <p>
                        If erasure is not technically possible or we believe that we have a good legal reason to continue processing personal data that you ask us to erase, we will tell you this and our reasoning at the time we respond to your request.
                        You can exercise this right at any time by writing to us (<a
                        href="mailto:Samantha@earnrelai.com">Samantha@earnrelai.com</a>) and telling us that you are making a request to have your personal data rectified or erased and on what basis you are making that request. If you want us to replace inaccurate data with new data, you should tell us what that new data is. You do not have to fill in a specific form to make this kind of request.
                    </p>
                    <h6>Your Right to Restrict Processing</h6>
                    <p>
                        Where we process your personal data on the basis of a legitimate interest, you are entitled to ask us to stop processing it in that way if you feel that our continuing to do so impacts on your fundamental rights and freedoms or if you feel that those legitimate interests are not valid.
                    </p>
                    <p>
                        You may also ask us to stop processing your personal data (a) if you dispute the accuracy of that personal data and want us verify that data’s accuracy; (b) where it has been established that our use of the data is unlawful but you do not want us to erase it; (c) where we no longer need to process your personal data (and would otherwise dispose of it) but you wish for us to continue storing it in order to enable you to establish, exercise or defend legal claims.
                        Please note that if for any reason we believe that we have a good legal reason to continue processing personal data that you ask us to stop processing, we will tell you what that reason is, either at the time we first respond to your request or after we have had the opportunity to consider and investigate it.
                    </p>
                    <p>
                        You can exercise this right at any time by writing to us (Samantha@earnrelai.com) and telling us that you are making a request to have us stop processing the relevant aspect of your personal data and describing which of the above conditions you believe is relevant to that request. You do not have to fill in a specific form to make this kind of request.
                    </p>
                    <h6>Your Right to Portability</h6>
                    <p>
                        Where you wish to transfer certain personal data that we hold about you, which is processed by automated means, to a third party, you may write to us and ask us to provide it to you in a commonly used machine-readable format.
                        Because of the kind of work that we do and the systems that we use, we do not envisage this right being particularly relevant to the majority of individuals with whom we interact. However, if you wish to transfer your data from us to a third party we are happy to consider such requests.
                    </p>
                    <h6>Your Right to object to processing</h6>
                    <p>
                        You may object to processing of your personal data where we rely on legitimate interest for processing that personal data. We will comply with your request unless we have a compelling overriding legitimate interest for processing or we need to continue processing your personal data to establish, exercise or defend a legal claim.
                    </p>
                    <h6>Your Right to stop receiving communications</h6>
                    <p>
                        To unsubscribe from communications at any time, please click on the unsubscribe link at the bottom of any email and update your notification preferences in the App. For details on your rights to ask us to stop sending you various kinds of communications, please contact us (Samantha@earnrelai.com).
                    </p>
                    <h6>Your Right to object to automated Decision Making and Profiling</h6>
                    <p>
                        You have the right to be informed about the existence of any automated decision making and profiling of your personal data, and where appropriate, be provided with meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing that affects you.
                    </p>
                    <h6>Withdrawal of Consent</h6>
                    <p>
                        Where we are relying on consent to process your personal data, you may withdraw consent at any time. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                    </p>
                    <h6>Exercising your rights</h6>
                    <p>
                        When you write to us making a request to exercise your rights, we are entitled to ask you to prove that you are who you say you are. We may ask you to provide copies of relevant ID documents to help us to verify your identity.
                        It will help us to process your request if you clearly state which right you wish to exercise and, where relevant, why it is that you are exercising it. The clearer and more specific you can be, the faster and more efficiently we can deal with your request. If you do not provide us with sufficient information, then we may delay actioning your request until you have provided us with additional information (and where this is the case, we will tell you).
                    </p>

                    <h4>Use of services</h4>
                    <h6>Use of Google Maps</h6>
                    <p>
                        This website uses the offer of Google Maps. This allows us to display interactive maps directly on the website and enables you to use the map function conveniently. By visiting the website, Google receives the information that you have called up the corresponding subpage of our website. This happens regardless of whether Google provides a user account through which you are logged in or whether no user account exists. If you are logged in at Google, your data will be assigned directly to your account. If you do not want the assignment with your profile at Google, you have to log out before activating the button. Google stores your data as user profiles and uses them for the purposes of advertising, market research and/or demand-oriented design of its website. Such an evaluation is carried out in particular (even for users who are not logged in) to provide need-based advertising and to inform other users of the social network about your activities on our website. You have the right to object to the creation of these user profiles, whereby you must contact Google to exercise this right. Further information on the purpose and scope of data collection and its processing by Google, in addition to further information on your rights in this regard and setting options for the protection of your privacy can be found at:
                        <a href="https://www.google.de/intl/de/policies/privacy" target="_blank">www.google.de/intl/de/policies/privacy</a>
                    </p>
                    <h6> Use of Google Remarketing</h6>
                    <p>
                        This website uses the remarketing function of Google Inc. to present interest-related advertisements to website visitors within the Google advertising network. A so-called “cookie” is stored in the website visitor’s browser, which makes it possible to recognize the visitor when he or she visits websites that belong to the Google advertising network. On these pages, the visitor may be presented with advertisements relating to content that the visitor has previously viewed on websites that use Google’s remarketing function.
                        According to its own statements, Google does not collect any personal data during this process. If you still do not wish to use Google’s remarketing function, you can deactivate it by making the appropriate settings at
                        <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a>. Alternatively, you can deactivate the use of cookies for interest-based advertising via the advertising network initiative by following the instructions at
                        <a href="http://www.networkadvertising.org/managing/opt_out.asp" target="_blank">http://www.networkadvertising.org/managing/opt_out.asp</a>.
                    </p>
                    <h6>Privacy policy for Google Analytics</h6>
                    <p>
                        This website uses Google Analytics, a web analysis service of Google Ireland Limited. If the person responsible for data processing on this website is located outside the European Economic Area or Switzerland, Google Analytics data processing is carried out by Google LLC. Google LLC and Google Ireland Limited are hereinafter referred to as “Google”.
                        We can use the statistics obtained to improve our offer and make it more interesting for you as a user. This website also uses Google Analytics for a cross-device analysis of visitor flows, which is performed using a user ID. If you have a Google user account, you can deactivate the cross-device analysis of your usage in the settings there under “My data”, “Personal data”.
                        The legal basis for the use of Google Analytics is Art. 6 para. 1 lit. f GDPR (EU General Data Protection Regulation). The IP address transmitted by your browser in the context of Google Analytics is not merged with other data from Google. We would like to point out that on this website Google Analytics has been extended by the code “_anonymizeIp();” in order to ensure anonymous collection of IP addresses. This means that IP addresses are further processed in a shortened form, thus excluding the possibility of personal references. If the data collected about you contains a personal reference, this is immediately excluded and the personal data is immediately deleted.
                        Only in exceptional cases will the full IP address be transferred to a Google server in the USA and shortened there. On behalf of the operator of this website, Google will use this information to evaluate your use of the website, to compile reports on the website activities and to provide further services to the website operator in connection with the use of the website and the Internet.
                    </p>
                    <p>
                        Google Analytics uses cookies. The information generated by the cookie about your use of this website is usually transferred to a Google server in the USA and stored there. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) to Google and the processing of this data by Google by downloading and installing the browser plugin available at the following link: deactivate Google Analytics.
                    </p>
                    <p>
                        You can also prevent the use of Google Analytics by clicking on this link: Deactivate Google Analytics. This will cause a so-called opt-out cookie to be stored on your data carrier, which prevents the processing of personal data by Google Analytics. Please note that if you delete all cookies on your end device, these opt-out cookies will also be deleted, which means that you will have to set the opt-out cookies again if you want to continue to prevent this form of data collection. The opt-out cookies are set per browser and computer/device and must therefore be activated separately for each browser, computer or other device.
                    </p>
                    <h6>Newsletter - Mailchimp</h6>
                    <p>
                        The newsletters are sent via the mailing service provider ‘MailChimp’, a newsletter dispatch platform of the US provider Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. You can view the data protection regulations of the mailing service provider here. The mail order service provider is used on the basis of our legitimate interests according to art. 6 para. 1 lit. f GDPR and an order processing contract according to art. 28 para. 3 GDPR.
                        The dispatch service provider may use the data of the recipients in pseudonymous form, i.e. without allocation to a user, to optimize or improve its own services, e.g. for technical optimization of dispatch and presentation of the newsletter or for statistical purposes. However, the dispatch service provider does not use the data of our newsletter recipients to write to them itself or to pass the data on to third parties.
                    </p>

                    <h4>Contact Details</h4>
                    <p>
                        If you have any queries regarding this Privacy Policy, if you wish to exercise any of your rights set out above, or if you think that the Privacy Policy has not been followed, please contact us by emailing at
                        <a href="mailto:Samantha@earnrelai.com">Samantha@earnrelai.com</a>
                    </p>

                </div>
            </div>
        </div>
    </div>
    <!-- * Terms Modal -->
</template>

<script>
export default {
    name: "privacy_policy"
}
</script>

<style scoped>
h5, h3,h4,h6{
    color: #12326B
}
h4{
    margin-top: 20px;
}
h6{
    margin-top: 12px;
}
p{
    line-height: 30px;
}
ul{
    padding-left: 2rem;
}
ul li{
    list-style-type: disc!important;
}
ul li::marker{
    color: #12326B;
}
</style>