<template>
    <div>
        <div class="section mt-2 text-center">
            <img class="auth-logo" src="../../assets/img/metriclogo_color.png" alt="metrics logo" style="">
            <h4 class="mt-3">Create an account</h4>
        </div>
        <div class="section mb-5 p-2">
            <form @submit.prevent="proceed">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="name">Username</label>
                                <input v-model="user.name" type="text" class="form-control" id="name" placeholder="Preferred username">
                            </div>
                        </div>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="email1">E-mail</label>
                                <input v-model="user.email" type="email" class="form-control" id="email1" placeholder="Your e-mail">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="phone">Phone</label>
                                <div class="row mt-2">
                                    <div class="col-2">
                                        <vue-country-code @onSelect="onSelect"></vue-country-code>
                                    </div>
                                    <div class="col-9 offset-1">
                                        <input v-model="user.phone" type="tel" class="form-control" id="phone" placeholder="Your phone number">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="password1">Password</label>
                                <input v-model="password_1" type="password" class="form-control" id="password1" autocomplete="off"
                                       placeholder="Your password">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="password2">Password Again</label>
                                <input v-model="password_2" type="password" class="form-control" id="password2" autocomplete="off"
                                       placeholder="Type password again">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic" v-if="referee_name">
                            <div class="input-wrapper">
                                <label class="label" for="referral">Referred by</label>
                                <input :value="referee_name" type="text" class="form-control" id="referral" autocomplete="off"
                                       placeholder="Referred" disabled>
                            </div>
                        </div>

                        <div class="custom-control custom-checkbox mt-2 mb-1">
                            <div class="form-check">
                                <input v-model="agree_to_terms" type="checkbox" class="form-check-input" id="customCheckb1">
                                <label class="form-check-label" for="customCheckb1">
                                    I agree to the <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#termsModal">terms and
                                    conditions</a> and <a href="javascript:void(0)" data-bs-target="#privacyModal" data-bs-toggle="modal">privacy policy</a>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Register</button>
                </div>

            </form>
        </div>

        <terms-conditions-modal/>
        <privacy-policy-modal/>
    </div>
</template>

<script>
import termsConditionsModal from "@/components/modals/terms_conditions";
import privacyPolicyModal from '../../components/modals/privacy_policy';
import methodsMixin from "../../utils/methodsMixin";

export default {
    name: "register",
    data(){
        return {
            user: new this.$models.UserModel(),
            referee_name: '',
            password_1: '',
            password_2: '',
            dial_code: '',
            agree_to_terms: false
        }
    },
    mixins: [methodsMixin],
    watch: {
      $route(){
          this.getReferralInfo()
      }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        onSelect({name, iso2, dialCode}){
            this.dial_code = dialCode;
            // console.log(name, iso2, dialCode);
        },
        async proceed(){
            // return
            if(this.validateFields() === 'done'){
                $('#loader').fadeIn();
                if(this.user.phone.startsWith('0')){
                    let tmp_phone = this.user.phone.split("").splice(1, this.user.phone.length).join("");
                    this.user.phone = '+' + this.dial_code + tmp_phone
                }else if(!this.user.phone.startsWith('+')){
                    this.user.phone = '+' + this.dial_code + this.user.phone
                }
                const {user} = this;
                let response = await this.$store.dispatch('auth/register', {
                    user, password: this.password_1
                });
                $('#loader').fadeOut();
                if(response.status){
                    this.$store.dispatch('notification/display_noti', {
                        message: 'Registration successful. Please login to continue.',
                        timeout: 1500
                    });
                    sessionStorage.setItem('RE-NEW', '1');
                    return setTimeout(()=>{
                        window.location.href = this.$router.resolve({name: 'Auth'})
                    }, 2000);
                }else{
                    return this.$store.dispatch('notification/display_noti', {
                        message: response.message,
                    })
                }
            }
        },
        validateFields(){
            if(!this.user.email || !this.password_1 || !this.user.name || !this.user.phone){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Fill in all fields!',
                    timeout: 1500
                })
            }
            if(!this.validateEmail(this.user.email)){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Invalid email address!',
                    timeout: 1500
                })
            }
            if(this.user.phone.length <= 4){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Enter a valid phone number',
                    timeout: 1500
                })
            }
            if(!(this.password_1 === this.password_2)){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Passwords do not match!',
                    timeout: 1500
                })
            }
            if(this.password_1.length <= 7){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Your password should be greater than 8 characters!',
                    timeout: 2000
                })
            }
            if(!this.agree_to_terms){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Agree to our terms to continue!',
                    timeout: 2000
                })
            }
            return 'done'
        },
        async getReferralInfo(){
            if(this.$route.params.userId){
                let response = await this.$store.dispatch('user/getUser', this.$route.params.userId);
                if(response.status){
                    this.user.referred_by = this.$route.params.userId;
                    this.user.referred = true;
                    this.referee_name = response.data.data.name;
                }
            }
        }
    },
    components: {
        termsConditionsModal,
        privacyPolicyModal
    },
    mounted(){
        this.getReferralInfo();
    }
}
</script>

<style scoped>
.auth-logo{
    width: 100px;
    height: 100px
}
</style>